// src/app/services/interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Component } from '@angular/core';
import { LoaderService } from '../loader/loader.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(public toastController: ToastController,
    private loader: LoaderService) { }
  
  async FormReg(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000
    });
    return toast.present();
  }

  handleError(err: HttpErrorResponse) {
    console.log(err);
    this.loader.dismissLoading()
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      switch (err.status) {
        case 400:
          errorMessage = "Bad Request.";
          break;
        case 401:
          errorMessage = "You need to log in to do this action.";
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = "The requested resource does not exist.";
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        case 503:
          errorMessage = "The requested service is not available.";
          break;
        default:
          errorMessage = "Something went wrong!";
      }
    }
    if (errorMessage) {
      this.FormReg(errorMessage)
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      console.log("interceptor called");
      const token = localStorage.getItem("token");
      if (token) {
        req = req.clone({
           setHeaders: {Authorization: `Bearer ${token}`}
        });
     }
     return new Observable((observer) => {
      next.handle(req).subscribe(
        (res: HttpResponse<any>) => {
          if (res instanceof HttpResponse) {
            observer.next(res);
          }
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
        }
      );
    });
  };

}
