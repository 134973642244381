import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    // Check if the user is logged in (your authentication logic here)
    const isLoggedIn = localStorage.getItem('isLoggedin');

    if (isLoggedIn) {
      this.router.navigate(['/home/tab1']);  // User is logged in, redirect to the dashboard
      return false; // Prevent access to the route
    } else if(!isLoggedIn) {
      return true;  // User is not logged in, allow access to the route
    }
    else {
      return true;
    }
  }
  
}
