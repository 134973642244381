import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(public http: HttpClient){ }
  get(url: string) {
    return this.http.get(url);
  }
  post(url: string, data) {
    console.log(url,data);
    console.log(this.http.post(url, data ))
    return this.http.post(url, data ) ;
  }
  put(url: string, data) {
    return this.http.post(url, data ) ;
  }
  delete(url: string) {
    return this.http.get(url);
  }

  exportexcelAllData(fileName, file12) {

    /* table id is passed over here */
    console.log(fileName, file12)
  //  let element = document.getElementById(file12);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(file12);
    console.log(ws)
    // ws['!cols'] = [];
    // ws['!cols'][2] = { hidden: true };use to remove specific column with values  2 column position
    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // fileName += Date.now()
    XLSX.writeFile(wb, fileName);

  }

}
