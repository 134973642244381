import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = false;

  constructor(public loadingController: LoadingController,
    private services: HttpService,
    public toastController: ToastController,
    public alertController: AlertController) { }

  async presentLoading() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'custom-loader',
      spinner: null
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  async presentToast(msgText, bgColor) {
    const toast = await this.toastController.create({
      message: msgText,
      color: bgColor,
      position: 'bottom',
      animated: true,
      duration: 3000
    });
    toast.present();
  }

  async presentAlert(message: any) {
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK']
    });
    await alert.present();
    console.log('onDidDismiss resolved with role');
  }

  
}
